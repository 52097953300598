import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { AccordionCustomSelects, TableCustom } from '../../../../components';
import get from 'lodash/get';
import {
  calcOpPriceHTWithMaterial,
  calcOpPriceTTC,
  calcOpPriceTTCWithMaterial,
  calcOpProPriceHT
} from '../Prices';
import { moneyFormatter } from '../../../../helpers/formatters';
import defaultImage from '../../../../assets/img/image_placeholder.jpg';

class GroupCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      groupIndex: 0,
      totalGroups: 0,
      selected: true,
      indexOpDelete: null,
      indexOpEdit: null
    };
  }

  componentWillReceiveProps({ group, groupIndex, totalGroups }) {
    this.setState({ group, groupIndex, totalGroups });
  }

  componentWillMount() {
    let { group, groupIndex, totalGroups } = this.props;
    this.setState({ group, groupIndex, totalGroups });
  }

  getMaterialsData(operation) {
    let materials = [];

    if (operation && operation.materials) {
      materials = operation.materials.map(material => {
        return {
          className: '',
          data: [
            {
              className: '',
              value: (
                <div
                  className="piece-surface-image"
                  style={{
                    backgroundImage: `url('${
                      material.image ? material.image : defaultImage
                    }')`
                  }}
                />
              )
            },
            { className: '', fixed: false, value: get(material, 'name', '') },
            {
              className: 'text-right',
              fixed: false,
              value: `${get(material, 'cant', 0)} / ${get(
                material,
                'unit',
                '-'
              )}`
            },
            {
              className: 'text-right',
              fixed: false,
              value: get(material, 'unit_price', '')
            },
            {
              className: 'text-right',
              fixed: false,
              value: moneyFormatter(
                material.unit_price * material.cant +
                  material.unit_price * material.cant * (operation.tva / 100)
              )
            }
          ]
        };
      });
    }

    return materials;
  }

  renderItems() {
    let { group } = this.state,
      { t } = this.props;
    let items = group && group.items && group.items.length ? group.items : [];

    return items.map((op, iIndex) => {
      let materials = this.getMaterialsData(op);

      let operation = {
        title: (
          <div>
            <strong className={'pl-2'}>{op.name}</strong>
            <span> - {op.group}</span>
            <div className={'ml-3 float-right'}>
              <Trans>T. Materials</Trans>:{' '}
              <strong>{moneyFormatter(calcOpPriceTTCWithMaterial(op))}</strong>
            </div>
            <div className={'float-right'}>
              <Trans>Total</Trans>:{' '}
              <strong>{moneyFormatter(calcOpPriceTTC(op))}</strong>
            </div>
          </div>
        ),
        text: (
          <Row key={iIndex} className={`devis-item`}>
            <Col xs={12} md={3}>
              <p>
                <strong>
                  <Trans>Unit price</Trans>
                </strong>
                {`: ${moneyFormatter(get(op, 'unit_price', '-'))} / ${get(
                  op,
                  'unit',
                  '-'
                )}`}
              </p>
            </Col>
            <Col xs={12} md={3}>
              <p>
                <strong>
                  <Trans>Pro price</Trans>
                </strong>
                {`: ${moneyFormatter(get(op, 'pro_price', '-'))} / ${get(
                  op,
                  'unit',
                  '-'
                )}`}
              </p>
            </Col>
            <Col xs={12} md={3}>
              <p>
                <strong>
                  <Trans>TVA</Trans>
                </strong>
                {`: ${get(op, 'tva', '-')} %`}
              </p>
            </Col>
            <Col xs={12} md={3}>
              <p>
                <strong>
                  <Trans>Amount</Trans>
                </strong>
                {`: ${get(op, 'amount', '-')} ${get(op, 'unit', '-')}`}
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <strong>
                  <Trans>Description</Trans>
                </strong>
                {`: ${get(op, 'description', '-')}`}
              </p>
            </Col>
            {materials.length ? (
              <Col xs={12} className={'mt-3'}>
                <strong className={'p-1'}>
                  <Trans>Materials</Trans>
                </strong>
                <TableCustom
                  minWidth={767}
                  className={'devi-item-material-table'}
                  accordion={true}
                  principal={1}
                  heads={[
                    { value: t('Image') },
                    { value: t('Name') },
                    { value: t('Amount'), className: 'text-right' },
                    { value: t('Unit Price'), className: 'text-right' },
                    { value: t('Price'), className: 'text-right' }
                  ]}
                  rows={materials}
                  emptyMessage={t('No materials found')}
                />
              </Col>
            ) : null}
          </Row>
        )
      };

      return [
        <AccordionCustomSelects
          key={`${group.name}-item-${iIndex}`}
          opIndex={iIndex}
          last={iIndex === items.length - 1}
          total={items.length}
          plain
          defaultOpened={-1}
          components={[operation]}
        />
      ].concat([]);
    });
  }

  getTotals() {
    let { group } = this.state;
    let totalPrice = 0;
    let totalPriceMat = 0;
    let totalProPrice = 0;
    let totalProPriceMat = 0;

    if (group && group.items && group.items.length) {
      group.items.forEach(op => {
        totalPrice += calcOpPriceTTC(op);
        totalPriceMat += calcOpPriceTTCWithMaterial(op);
        totalProPrice += calcOpProPriceHT(op);
        totalProPriceMat += calcOpPriceHTWithMaterial(op, true);
      });
    }

    return { totalPrice, totalPriceMat, totalProPrice, totalProPriceMat };
  }

  render() {
    let { group, selected } = this.state;
    let operations = this.renderItems();
    let totalsGroup = this.getTotals();

    return (
      <div>
        <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
          <CardHeader className={'pt-2'}>
            <Row>
              <Col
                xs={12}
                className={'pl-0 pr-0'}
                onClick={() => this.setState({ selected: !selected })}
              >
                <Row>
                  <Col xs={12} md={4}>
                    <h6>{get(group, 'name', '')}</h6>
                  </Col>
                  <Col xs={12} md={2} className={'pl-0'}>
                    <p className={'mb-0'}>
                      <strong>
                        <Trans>TTC</Trans>
                      </strong>
                      {`: ${moneyFormatter(get(totalsGroup, 'totalPrice', 0))}`}
                    </p>
                  </Col>
                  <Col xs={12} md={2} className={'pl-0'}>
                    <p className={'mb-0'}>
                      <strong>
                        <Trans>TTC + Materials</Trans>
                      </strong>
                      {`: ${moneyFormatter(
                        get(totalsGroup, 'totalPriceMat', 0)
                      )}`}
                    </p>
                  </Col>
                  <Col xs={12} md={2} className={'pl-0'}>
                    <p className={'mb-0'}>
                      <strong>
                        <Trans>Pro HT</Trans>
                      </strong>
                      {`: ${moneyFormatter(
                        get(totalsGroup, 'totalProPrice', 0)
                      )}`}
                    </p>
                  </Col>
                  <Col xs={12} md={2} className={'pl-0'}>
                    <p className={'mb-0'}>
                      <strong>
                        <Trans>Pro HT + Materials</Trans>
                      </strong>
                      {`: ${moneyFormatter(
                        get(totalsGroup, 'totalProPriceMat', 0)
                      )}`}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <CardBody
            className={'pt-0 pb-0'}
            style={{ display: selected ? 'block' : 'none' }}
          >
            <hr />
            {operations}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(GroupCard));
