import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Table, CardHeader } from 'reactstrap';
import get from 'lodash/get';
import { moneyFormatter } from '../../../helpers/formatters';

class PaymentsCard extends Component {
  render() {
    let { payments, devis } = this.props;

    return (
      <Card>
        <CardHeader>
          <h6>
            <Trans>Payments</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          {payments && devis ? (
            <Table responsive>
              <tbody>
                {payments ? (
                  payments.map(payment => {
                    return payment.parent ? null : (
                      <tr key={payment._id}>
                        <td>{get(payment, 'description', '')}</td>
                        <td className="text-right">
                          {moneyFormatter(
                            ((get(devis, 'price.ttc', 0) +
                              get(devis, 'price.materials', 0)) *
                              get(payment, 'percent', 0)) /
                              100
                          )}
                        </td>
                        <td className="text-right">{`${get(
                          payment,
                          'percent',
                          ''
                        )} %`}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td />
                  </tr>
                )}
              </tbody>
            </Table>
          ) : null}
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(PaymentsCard));
