import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  ModalFooter
} from 'reactstrap';
import { Spinner } from '../../../../components/index';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { all as allPieces } from '../../../../helpers/actions/pieces';
import { all as allSurfaces } from '../../../../helpers/actions/surfaces';
import { moneyFormatter } from '../../../../helpers/formatters';
import { units } from '../../../../helpers/nomenclators';
import { calcOpPriceTTC, getProPrice } from '../Prices';

class OpCustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      operation: {
        unit_price: 1,
        amount: 1
      },
      pieces: [],
      surfaces: []
    };
  }

  componentWillMount() {
    let { provider, typeProject } = this.props;
    this.setState({ loading: true });

    let { operation } = this.state,
      { dispatch } = this.props;
    let type = typeProject === 'compound-son' ? 'compound' : typeProject;

    Promise.all([
      dispatch(allPieces({ type })),
      dispatch(allSurfaces({ type }))
    ])
      .then(([pieces, surfaces]) => {
        operation.pro_price = getProPrice(provider, operation.unit_price);
        operation.tva = provider && provider.tva ? provider.tva : 10;

        this.setState(ps => ({
          ...ps,
          operation,
          pieces: pieces.data,
          surfaces: surfaces.data,
          loading: false
        }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  cleanModal() {
    this.setState({
      nameValid: null,
      unitValid: null,
      groupValid: null,
      piecesValid: null,
      descriptionValid: null,
      unitPriceValid: null,
      proPriceValid: null,
      amountPriceValid: null,
      tvaPriceValid: null,
      operation: {
        unit_price: 1,
        amount: 1,
        tva: 10
      },
      surfaces: [],
      pieces: []
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    this.setState(prevState => {
      return {
        ...prevState,
        operation: { ...prevState.operation, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      };
    });
  }

  onChangeUnitPrice(name, nameValid, value, valid) {
    let { provider } = this.props;
    let proPrice = getProPrice(provider, value);
    this.setState(prevState => {
      return {
        ...prevState,
        operation: {
          ...prevState.operation,
          [name]: value,
          pro_price: proPrice
        },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      };
    });
  }

  validate() {
    let { operation } = this.state;
    let name = this.name;
    let description = this.description;
    let unit_price = this.unit_price;
    let pro_price = this.pro_price;
    let amount = this.amount;
    let tva = this.tva;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      unitValid: operation.unit ? 'has-success' : 'has-danger',
      groupValid: operation.group ? 'has-success' : 'has-danger',
      pieceValid: operation.piece ? 'has-success' : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [unit_price.attributes.getNamedItem('namevalid').value]: unit_price
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [pro_price.attributes.getNamedItem('namevalid').value]: pro_price.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [amount.attributes.getNamedItem('namevalid').value]: amount.validity.valid
        ? 'has-success'
        : 'has-danger',
      [tva.attributes.getNamedItem('namevalid').value]: tva.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      operation.unit &&
      operation.group &&
      operation.piece &&
      description.validity.valid &&
      unit_price.validity.valid &&
      pro_price.validity.valid &&
      amount.validity.valid &&
      tva.validity.valid
    );
  }

  onSaveOperation() {
    let { operation } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(operation);
    }
  }

  render() {
    let { loading, operation, pieces, surfaces } = this.state;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>New Operation Custom</Trans>
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.nameValid
                }
              >
                <Label>
                  <Trans>Name</Trans>
                </Label>
                <Input
                  type="text"
                  innerRef={node => (this.name = node)}
                  namevalid="nameValid"
                  value={operation.name || ''}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'name',
                      'nameValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.unitValid
                }
              >
                <Label>
                  <Trans>Unit</Trans>
                </Label>
                <CreatableSelect
                  namevalid="unitValid"
                  className="primary"
                  closeOnSelect={true}
                  value={operation.unit}
                  options={units.map(p => ({ label: p, value: p }))}
                  onNewOptionClick={event => {
                    units.push(event.value);
                    this.onChange(
                      'unit',
                      'unitValid',
                      event ? event.value : null,
                      !!event
                    );
                  }}
                  onChange={event =>
                    this.onChange(
                      'unit',
                      'unitValid',
                      event ? event.value : null,
                      !!event
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.pieceValid
                }
              >
                <Label>
                  <Trans>Piece</Trans>
                </Label>
                <Select
                  className="primary"
                  options={pieces.map(t => ({
                    label: t.name,
                    value: t.name
                  }))}
                  value={operation.piece}
                  onChange={event =>
                    this.onChange(
                      'piece',
                      'pieceValid',
                      event ? event.value : null,
                      !!event
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.groupValid
                }
              >
                <Label>
                  <Trans>Surface</Trans>
                </Label>
                <Select
                  className="primary"
                  options={surfaces.map(t => ({
                    label: t.name,
                    value: t.name
                  }))}
                  value={operation.group}
                  onChange={event =>
                    this.onChange(
                      'group',
                      'groupValid',
                      event ? event.value : null,
                      !!event
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.descriptionValid
                }
              >
                <Label>
                  <Trans>Description</Trans>
                </Label>
                <Input
                  type="textarea"
                  innerRef={node => (this.description = node)}
                  namevalid="descriptionValid"
                  value={operation.description || ''}
                  onChange={event =>
                    this.onChange(
                      'description',
                      'descriptionValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.unitPriceValid
                }
              >
                <Label>
                  <Trans>Unit Price</Trans>
                </Label>
                <Input
                  type="number"
                  innerRef={node => (this.unit_price = node)}
                  namevalid="unitPriceValid"
                  value={operation.unit_price || ''}
                  step={0.01}
                  min={0.01}
                  required="required"
                  onChange={event =>
                    this.onChangeUnitPrice(
                      'unit_price',
                      'unitPriceValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.proPriceValid
                }
              >
                <Label>
                  <Trans>Pro Price</Trans>
                </Label>
                <Input
                  type="number"
                  innerRef={node => (this.pro_price = node)}
                  namevalid="proPriceValid"
                  value={operation.pro_price || ''}
                  step={0.01}
                  min={0.01}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'pro_price',
                      'proPriceValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.amountValid
                }
              >
                <Label>
                  <Trans>Amount</Trans>
                </Label>
                <Input
                  type="number"
                  innerRef={node => (this.amount = node)}
                  namevalid="amountValid"
                  value={operation.amount || ''}
                  step={0.01}
                  min={0.01}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'amount',
                      'amountValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.tvaValid
                }
              >
                <Label>
                  <Trans>TVA</Trans>
                  {' (%)'}
                </Label>
                <Input
                  type="number"
                  innerRef={node => (this.tva = node)}
                  namevalid="tvaValid"
                  value={operation.tva || ''}
                  step={1}
                  min={1}
                  max={100}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'tva',
                      'tvaValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-right">
              <p>
                <strong>
                  <Trans>Total Price</Trans>
                </strong>
                {': '}
                {moneyFormatter(calcOpPriceTTC(operation))}
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveOperation()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(OpCustomModal));
