import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AccordionCustomSelects,
  AlertConfirm,
  Button
} from '../../../../components/index';
import Number from '../../../../components/NumberField/Number';
import Select from 'react-select';
import { sortableHandle } from 'react-sortable-hoc';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import {
  calcOpPriceHTWithMaterial,
  calcOpPriceTTC,
  calcOpPriceTTCWithMaterial,
  calcOpProPriceHT,
  getProPrice
} from '../Prices';
import { moneyFormatter } from '../../../../helpers/formatters';
import Materials from '../Materials/Materials';

const DragHandle = sortableHandle(() => (
  <Button color="info" round icon className={'float-md-left'}>
    <i className="fa fa-arrows icon-action" />
  </Button>
));

class GroupCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      provider: null,
      groupIndex: 0,
      totalGroups: 0,
      selected: true,
      indexOpDelete: null,
      indexOpEdit: null
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState(ps => ({
      ...ps,
      group: nextProps.group,
      groupIndex: nextProps.groupIndex,
      totalGroups: nextProps.totalGroups,
      provider: nextProps.provider
    }));
  }

  componentWillMount() {
    let { group, groupIndex, totalGroups, provider } = this.props;
    this.setState({ group, groupIndex, totalGroups, provider });
  }

  onChange(itemIndex, name, value) {
    let { group, groupIndex } = this.state;

    if (group.items && group.items.length && group.items.length > itemIndex) {
      let operation = group.items[itemIndex];

      operation[name] = value;

      this.props.editOperation(groupIndex, itemIndex, operation);
    }
  }

  onChangeUnitPrice(itemIndex, name, value) {
    let { group, groupIndex, provider } = this.state;
    let proPrice = getProPrice(provider, value);

    if (group.items && group.items.length && group.items.length > itemIndex) {
      let operation = group.items[itemIndex];

      operation[name] = value;
      operation.pro_price = proPrice;

      this.props.editOperation(groupIndex, itemIndex, operation);
    }
  }

  renderItems() {
    let { group, groupIndex } = this.state;
    let items = group && group.items && group.items.length ? group.items : [];

    return items.map((op, iIndex) => {
      let operation = {
        title: (
          <div>
            <strong className={'pl-2'}>{op.name}</strong>
            <span> - {op.group}</span>
            <div className={'ml-3 float-right'}>
              <Trans>T. Materials</Trans>:{' '}
              <strong>{moneyFormatter(calcOpPriceTTCWithMaterial(op))}</strong>
            </div>
            <div className={'float-right'}>
              <Trans>Total</Trans>:{' '}
              <strong>{moneyFormatter(calcOpPriceTTC(op))}</strong>
            </div>
          </div>
        ),
        text: (
          <Row key={iIndex} className={`devis-item`}>
            <Col xs={12} md={3}>
              <FormGroup className={'has-label text-center'}>
                <Label>
                  <Trans>Unit Price</Trans>
                </Label>
                <Number
                  value={op.unit_price}
                  step={0.01}
                  min={1}
                  required="required"
                  onChange={value =>
                    this.onChangeUnitPrice(iIndex, 'unit_price', value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup className={'has-label text-center'}>
                <Label>
                  <Trans>Pro Price</Trans>
                </Label>
                <Number
                  value={op.pro_price}
                  step={0.01}
                  min={1}
                  required="required"
                  onChange={value => this.onChange(iIndex, 'pro_price', value)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup className={'has-label text-center'}>
                <Label>
                  <Trans>TVA</Trans>
                  {' (%)'}
                </Label>
                <Number
                  value={op.tva}
                  step={1}
                  min={1}
                  max={100}
                  required="required"
                  onChange={value => this.onChange(iIndex, 'tva', value)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup className={'has-label text-center'}>
                <Label>
                  <Trans>Amount</Trans>
                  {` (${get(op, 'unit', '-')})`}
                </Label>
                <Number
                  value={op.amount}
                  step={0.01}
                  min={0.01}
                  required="required"
                  onChange={value => this.onChange(iIndex, 'amount', value)}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup className={'has-label'}>
                <Label>
                  <Trans>Description</Trans>
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  namevalid="descriptionValid"
                  value={op.description ? op.description : ''}
                  onChange={event =>
                    this.onChange(iIndex, 'description', event.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} className={'mt-3'}>
              {
                <Materials
                  operation={op}
                  updateOperation={operation =>
                    this.props.editOperation(groupIndex, iIndex, operation)
                  }
                />
              }
            </Col>
          </Row>
        )
      };

      return [
        <AccordionCustomSelects
          key={`${group.name}-item-${iIndex}`}
          opIndex={iIndex}
          last={iIndex === items.length - 1}
          total={items.length}
          plain
          dOp={() => this.setState({ indexOpDelete: iIndex })}
          changeOrder={(value, opIndex) => {
            this.props.changeOpOrder(groupIndex, value, opIndex);
          }}
          defaultOpened={-1}
          components={[operation]}
        />
      ].concat([]);
    });
  }

  getTotals() {
    let { group } = this.state;
    let totalPrice = 0;
    let totalPriceMat = 0;
    let totalProPrice = 0;
    let totalProPriceMat = 0;

    if (group && group.items && group.items.length) {
      group.items.forEach(op => {
        totalPrice += calcOpPriceTTC(op);
        totalPriceMat += calcOpPriceTTCWithMaterial(op);
        totalProPrice += calcOpProPriceHT(op);
        totalProPriceMat += calcOpPriceHTWithMaterial(op, true);
      });
    }

    return { totalPrice, totalPriceMat, totalProPrice, totalProPriceMat };
  }

  render() {
    let {
      group,
      selected,
      groupIndex,
      totalGroups,
      indexOpDelete
    } = this.state;
    let operations = this.renderItems();
    let totalsGroup = this.getTotals();
    let sort = Array.from({ length: totalGroups }, (v, i) => i);

    return (
      <div>
        <Card>
          <CardHeader className={'pt-2'}>
            <div className="table-full-width table-responsive">
              <table className="table table-no-border m-0">
                <tbody>
                  <tr>
                    <td width="10%">
                      <DragHandle />
                      <Select
                        className="info float-md-left pl-md-2 mt-2 mt-md-0"
                        clearable={false}
                        value={groupIndex + 1}
                        options={sort.map(g => ({
                          label: g + 1,
                          value: g + 1
                        }))}
                        onChange={option =>
                          this.props.changeGroupOrder(
                            groupIndex,
                            option.value - 1
                          )
                        }
                      />
                    </td>
                    <td className="text-left">
                      <h6>{get(group, 'name', '')}</h6>
                      <p className={'float-md-left mb-0'}>
                        <strong>
                          <Trans>TTC</Trans>
                        </strong>
                        {`: ${moneyFormatter(
                          get(totalsGroup, 'totalPrice', 0)
                        )}`}
                      </p>
                      <p className={'float-md-left mb-0 pl-0 pl-md-3'}>
                        <strong>
                          <Trans>TTC + Mat</Trans>
                        </strong>
                        {`: ${moneyFormatter(
                          get(totalsGroup, 'totalPriceMat', 0)
                        )}`}
                      </p>
                      <p className={'float-md-left mb-0 pl-0 pl-md-3'}>
                        <strong>
                          <Trans>Pro HT</Trans>
                        </strong>
                        {`: ${moneyFormatter(
                          get(totalsGroup, 'totalProPrice', 0)
                        )}`}
                      </p>
                      <p className={'float-md-left mb-0 pl-0 pl-md-3'}>
                        <strong>
                          <Trans>Pro HT + Mat</Trans>
                        </strong>
                        {`: ${moneyFormatter(
                          get(totalsGroup, 'totalProPriceMat', 0)
                        )}`}
                      </p>
                    </td>
                    <td className="td-actions text-right">
                      <Button
                        color={'info'}
                        onClick={() =>
                          this.props.addFromCatalogFilter({ piece: group.name })
                        }
                      >
                        <i className="now-ui-icons ui-1_simple-add" />{' '}
                        <Trans>Catalog</Trans>
                      </Button>
                      <Button
                        color="default"
                        neutral
                        round
                        icon
                        onClick={() => this.setState({ selected: !selected })}
                      >
                        <i
                          className={`fa ${selected ? 'fa-minus' : 'fa-plus'}`}
                        />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardHeader>
          <CardBody
            className={'pt-0 pb-0'}
            style={{ display: selected ? 'block' : 'none' }}
          >
            <hr className={'mt-0'} />
            {operations}
          </CardBody>
        </Card>

        {indexOpDelete !== null && indexOpDelete >= 0 ? (
          <AlertConfirm
            message={'The operation cannot be recovered'}
            onCancel={() => this.setState({ indexOpDelete: null })}
            onConfirm={() => {
              let operationIndex = cloneDeep(indexOpDelete);
              this.setState({ indexOpDelete: null }, () =>
                this.props.deleteOperation(groupIndex, operationIndex)
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(GroupCard));
