import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { FormInputs } from '../../../../components';
import debounce from 'lodash/debounce';
import { all } from '../../../../helpers/actions/leroymerlin';
import Suggestions from './Suggestions';

class AsyncSearch extends React.Component {
  state = {
    allowNew: true,
    isLoading: false,
    multiple: false,
    options: [],
    query: ''
  };

  render() {
    let { t } = this.props;
    return (
      <Fragment>
        <FormInputs
          ncols={['col-12']}
          proprieties={[
            {
              label: t('Search'),
              inputProps: {
                type: 'search',
                value: this.state.query,
                onChange: e => {
                  this.setState(
                    {
                      query: e.target.value,
                      isLoading: !!e.target.value
                    },
                    () => this._handleSearch()
                  );
                }
              }
            }
          ]}
        />
        <div className="suggestions" style={{ minHeight: 120 }}>
          <Suggestions
            query={this.state.query}
            onSelected={m => this.props.onSelected([m])}
            results={this.state.options}
            loading={this.state.isLoading}
          />
        </div>
      </Fragment>
    );
  }

  _handleSearch = debounce(q => {
    let { query } = this.state;

    if (query.length) {
      let { dispatch } = this.props;
      dispatch(all({ search: query }))
        .then(({ data }) => {
          this.setState({ options: data, isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false, options: [] }));
    } else {
      this.setState({ isLoading: false, options: [] });
    }
  }, 300);
}

export default connect()(translate('translations-fr')(AsyncSearch));
