import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { Button, PanelHeader, Spinner } from '../../../../components/index';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import SweetAlert from 'react-bootstrap-sweetalert';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import {
  loadOneDevis,
  one,
  saveOneDevis
} from '../../../../helpers/actions/projects';
import {
  devisPriceTTC,
  devisPriceTTCMaterials,
  devisProPriceHT,
  devisProPriceHTMaterials
} from '../Prices';
import { moneyFormatter, quillConfig } from '../../../../helpers/formatters';
import CatalogModal from '../../../Operations/OpCatalogModal';
import PackCatalogModal from '../../../Packages/PackCatalog/PackCatalogModal';
import GroupCard from './GroupCard';
import OpCustomModal from './OpCustomModal';
import DevisStatusModal from '../DevisStatusModal';
import NavbarProject from '../../NavbarActions/NavbarProject';
import PaymentsCard from '../PaymentsCard';
import { isMobile } from 'react-device-detect';

const SortableItem = sortableElement(
  ({
    group,
    index,
    totalGroups,
    provider,
    addFromCatalogFilter,
    editOperation,
    deleteOperation,
    changeOpOrder,
    changeGroupOrder
  }) => (
    <GroupCard
      group={group}
      groupIndex={index}
      totalGroups={totalGroups}
      provider={provider}
      addFromCatalogFilter={filters => addFromCatalogFilter(filters)}
      editOperation={(groupIndex, operationIndex, operation) =>
        editOperation(groupIndex, operationIndex, operation)
      }
      deleteOperation={(groupIndex, operationIndex) =>
        deleteOperation(groupIndex, operationIndex)
      }
      changeOpOrder={(groupIndex, iIndex, opIndex) =>
        changeOpOrder(groupIndex, iIndex, opIndex)
      }
      changeGroupOrder={(groupIndex, iIndex) =>
        changeGroupOrder(groupIndex, iIndex)
      }
    />
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class DevisEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      devis: null,
      devisPre: null,
      project: null,
      provider: null,
      pieces: [],
      piece: null,
      fromCustom: false,
      fromCatalog: false,
      fromPackage: false,
      filters: { piece: null },
      changeStatus: false,
      alert: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { id, devisId } = this.props.match.params,
      { dispatch } = this.props;

    let promises = [
      dispatch(
        one(id, {
          include: ['devis', 'devisBase', 'provider'],
          fields: [
            'type',
            'name',
            'status',
            'devisBase',
            'devis',
            'payments',
            'provider'
          ]
        })
      )
    ];

    if (devisId) {
      promises.push(
        dispatch(
          loadOneDevis(id, devisId, {
            fields: [
              'title',
              'price',
              'base',
              'project',
              'groups',
              'parent',
              'status',
              'user',
              'created_at',
              'updated_at'
            ]
          })
        )
      );
    }

    Promise.all(promises)
      .then(([project, devis]) => {
        if (!devis) {
          devis = project.devisBase
            ? {
                project: project._id,
                groups: project.devisBase.groups,
                parent: project.devisBase._id,
                base: false
              }
            : {
                project: project._id,
                groups: [],
                parent: null,
                base: false
              };
        }

        let pieces = [];
        if (devis && devis.groups && devis.groups.length) {
          pieces = devis.groups.map(group => {
            return group.name;
          });
        }

        this.setState(
          prevState => {
            return {
              ...prevState,
              pieces,
              project,
              devis,
              devisPre: _.cloneDeep(devis),
              provider: project.provider,
              loading: false
            };
          },
          () => {}
        );
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  componentWillMount() {
    this.load();
  }

  componentDidMount() {
    // Auto save devis if exist a new change every 30 seconds
    this.autoSave = setInterval(() => {
      let { devis, devisPre, project } = this.state;
      if (
        !_.isEqual(devis, devisPre) &&
        this.validate() &&
        devis.hasOwnProperty('_id') &&
        devis.status !== 'APPROVED'
      ) {
        let { dispatch } = this.props;
        dispatch(saveOneDevis(project._id, { ...devis, project: project._id }))
          .then(res => {
            this.setState(ps => ({ ...ps, devisPre: _.cloneDeep(devis) }));
          })
          .catch(err => {});
      }
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.autoSave);
  }

  onChange(name, nameValid, value, valid) {
    let { devis } = this.state;

    this.setState({
      devis: { ...devis, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let { t } = this.props;
    let title = this.title;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger',
      alert: title.validity.valid
        ? null
        : t('The title of the quotation is wrong')
    });

    return title.validity.valid;
  }

  saveDevis() {
    if (this.validate()) {
      this.setState({ loading: true });

      let { devis, project } = this.state,
        { dispatch, history } = this.props;

      if (devis.status !== 'APPROVED') {
        dispatch(saveOneDevis(project._id, { ...devis, project: project._id }))
          .then(newDevis => {
            this.setState(prevState => {
              return {
                ...prevState,
                loading: false,
                devis: newDevis,
                devisPre: _.cloneDeep(newDevis)
              };
            });
            history.push(`/projects/${project._id}/devis/${newDevis._id}`);
          })
          .catch(() => this.setState(ps => ({ ...ps, loading: false })));
      }
    }
  }

  addFromCatalogFilter(filters) {
    this.setState({ filters, fromCatalog: true });
  }

  addOpCustom(operation) {
    let { devis, pieces } = this.state;
    operation.materials = [];

    let groupIndex = devis.groups.findIndex(t => t.name === operation.piece);

    if (groupIndex > -1) {
      operation.order = devis.groups[groupIndex].items.length;
      devis.groups[groupIndex].items.push(operation);
    } else {
      operation.order = 0;
      let newGroup = {
        name: operation.piece,
        order: devis.groups.length,
        items: [operation]
      };
      devis.groups.push(newGroup);
      pieces.push(operation.piece);
    }

    this.setState(prevState => {
      return { ...prevState, devis: { ...devis }, pieces: [...pieces] };
    });
  }

  getAllOperations() {
    let { devis } = this.state;
    let operations = [];

    if (devis) {
      devis.groups.forEach(g => {
        operations = operations.concat(
          g.items && g.items.length ? g.items : []
        );
      });
    }

    return operations;
  }

  addFromCatalog(selected) {
    let { devis, pieces } = this.state;

    selected.forEach(operation => {
      let groupIndex = devis.groups.findIndex(g => g.name === operation.piece);

      if (groupIndex > -1) {
        operation.order = devis.groups[groupIndex].items.length;
        devis.groups[groupIndex].items.push(operation);
      } else {
        operation.order = 0;
        let newGroup = {
          name: operation.piece,
          order: devis.groups.length,
          items: [operation]
        };
        devis.groups.push(newGroup);
        pieces.push(operation.piece);
      }
    });

    this.setState(prevState => {
      return { ...prevState, devis: { ...devis }, pieces: [...pieces] };
    });
  }

  addFromPackages(selected) {
    let { devis, pieces } = this.state;

    if (selected && selected.length) {
      selected.forEach(operation => {
        let groupIndex = devis.groups.findIndex(
          g => g.name === operation.piece
        );

        if (groupIndex > -1) {
          operation.order = devis.groups[groupIndex].items.length;
          devis.groups[groupIndex].items.push(operation);
        } else {
          operation.order = 0;
          let newGroup = {
            name: operation.piece,
            order: devis.groups.length,
            items: [operation]
          };
          devis.groups.push(newGroup);
          pieces.push(operation.piece);
        }
      });

      this.setState(prevState => {
        return { ...prevState, devis: { ...devis }, pieces: [...pieces] };
      });
    }
  }

  deleteOperation(groupIndex, operationIndex) {
    let { devis, pieces } = this.state;

    try {
      if (devis.groups[groupIndex].items.length > 1) {
        //delete operation
        devis.groups[groupIndex].items.splice(operationIndex, 1);

        //reorder operations
        this.reorderOperations(devis.groups[groupIndex].items);
      } else {
        //delete piece from filter
        let index = pieces.findIndex(t => t === devis.groups[groupIndex].name);
        if (index > -1) pieces.splice(index, 1);

        //delete group
        devis.groups.splice(groupIndex, 1);
      }

      this.setState(prevState => {
        return { ...prevState, devis: { ...devis }, pieces: [...pieces] };
      });
    } catch (err) {}
  }

  reorderOperations = items => {
    items.forEach((item, index) => {
      item.order = index;
    });
  };

  editOperation(groupIndex, operationIndex, operation) {
    let { devis } = this.state;

    try {
      //edit operation
      devis.groups[groupIndex].items[operationIndex] = operation;

      this.setState(prevState => {
        return { ...prevState, devis: { ...devis } };
      });
    } catch (err) {}
  }

  changeOpOrder(groupIndex, iIndex, opIndex) {
    let { devis } = this.state;

    try {
      if (
        iIndex >= 0 &&
        opIndex >= 0 &&
        iIndex !== opIndex &&
        devis.groups[groupIndex].items.length > iIndex &&
        devis.groups[groupIndex].items.length > opIndex &&
        devis.groups[groupIndex].items[iIndex].hasOwnProperty('order') &&
        devis.groups[groupIndex].items[opIndex].hasOwnProperty('order')
      ) {
        // move down the operation and reorder the other ones
        if (iIndex < opIndex) {
          for (let i = iIndex; i < devis.groups[groupIndex].items.length; i++) {
            if (i !== opIndex) {
              devis.groups[groupIndex].items[i].order = i + 1;
            } else {
              devis.groups[groupIndex].items[i].order = iIndex;
            }
          }
        }
        // move up the operation and reorder the other ones
        if (iIndex > opIndex) {
          for (
            let i = opIndex;
            i < devis.groups[groupIndex].items.length;
            i++
          ) {
            if (i !== opIndex) {
              devis.groups[groupIndex].items[i].order = i - 1;
            } else {
              devis.groups[groupIndex].items[i].order = iIndex;
            }
          }
        }
      }

      devis.groups[groupIndex].items.sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      );

      this.setState(prevState => {
        return { ...prevState, devis: { ...devis } };
      });
    } catch (err) {}
  }

  changeGroupOrder(groupIndex, iIndex) {
    let { devis } = this.state;

    try {
      if (
        iIndex >= 0 &&
        groupIndex >= 0 &&
        iIndex !== groupIndex &&
        devis.groups.length > iIndex &&
        devis.groups.length > groupIndex &&
        devis.groups[iIndex].hasOwnProperty('order') &&
        devis.groups[groupIndex].hasOwnProperty('order')
      ) {
        // move down the group and reorder the other ones
        if (iIndex < groupIndex) {
          for (let i = iIndex; i < devis.groups.length; i++) {
            if (i !== groupIndex) {
              devis.groups[i].order = i + 1;
            } else {
              devis.groups[i].order = iIndex;
            }
          }
        }
        // move up the group and reorder the other ones
        if (iIndex > groupIndex) {
          for (let i = groupIndex; i < devis.groups.length; i++) {
            if (i !== groupIndex) {
              devis.groups[i].order = i - 1;
            } else {
              devis.groups[i].order = iIndex;
            }
          }
        }
      }

      devis.groups.sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      );

      this.setState(prevState => {
        return { ...prevState, devis: { ...devis } };
      });
    } catch (err) {}
  }

  changeDevisStatus(value) {
    this.setState({ loading: true });

    let { devis, devisPre, project } = this.state,
      { dispatch, t } = this.props;
    let status = value ? value : 'CREATED';

    if (_.isEqual(devis, devisPre)) {
      dispatch(
        saveOneDevis(project._id, {
          status: status,
          _id: devis._id,
          project: project._id
        })
      )
        .then(() => this.load())
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState(ps => ({
        ...ps,
        alert: t(
          'There are unsaved changes in the devi. To modify the state of the devi you must save the changes made'
        ),
        loading: false
      }));
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.changeGroupOrder(oldIndex, newIndex);
  };

  printDevis = (pid, id, type) => {
    return this.props.history.push(
      `/projects/${pid}/devis/${id}/print?type=${type}`
    );
  };

  render() {
    let {
        loading,
        project,
        devis,
        provider,
        pieces,
        piece,
        fromCustom,
        fromCatalog,
        fromPackage,
        filters,
        changeStatus,
        alert
      } = this.state,
      { t } = this.props;

    devis = _.cloneDeep(devis);

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <NavbarProject project={project} history={this.props.history} />

          <Card>
            <CardBody>
              {devis ? (
                <Form>
                  <Row>
                    <Col xs={12} md={12} lg={6}>
                      <FormGroup
                        className={'has-label ' + this.state.titleValid}
                      >
                        <Label>
                          <Trans>Title</Trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.title = node)}
                          namevalid="titleValid"
                          value={devis.title || ''}
                          required="required"
                          onChange={event =>
                            this.onChange(
                              'title',
                              'titleValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={12} lg={2} className={'pt-lg-3'}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={devis.base}
                            onChange={event =>
                              this.setState({
                                devis: {
                                  ...devis,
                                  base: event.target.checked
                                }
                              })
                            }
                          />
                          <span className="form-check-sign" />
                          <Trans>Devis Base</Trans>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={12} lg={4}>
                      {devis.status !== 'APPROVED' ? (
                        <Button
                          className="float-right"
                          color={'primary'}
                          onClick={() => this.saveDevis()}
                        >
                          <i className="fa fa-check" /> <Trans>Validate</Trans>
                        </Button>
                      ) : null}
                      {devis && devis._id
                        ? [
                            <Button
                              key={'button-status'}
                              className="float-right"
                              color={'info'}
                              onClick={() =>
                                this.setState({ changeStatus: true })
                              }
                            >
                              <i className="fa fa-pencil-alt" />{' '}
                              <Trans>Status</Trans>
                            </Button>,
                            <UncontrolledDropdown
                              key={`print-devis-list`}
                              className={'float-right'}
                            >
                              <DropdownToggle color="info" className="" caret>
                                <i className="fa fa-lg fa-print" />{' '}
                                {!isMobile ? <Trans>Print</Trans> : ''}
                              </DropdownToggle>
                              <DropdownMenu right={true}>
                                <DropdownItem
                                  onClick={() =>
                                    this.printDevis(
                                      project._id,
                                      devis._id,
                                      'provider'
                                    )
                                  }
                                >
                                  <Trans>Provider</Trans>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    this.printDevis(
                                      project._id,
                                      devis._id,
                                      'pro'
                                    )
                                  }
                                >
                                  <Trans>Professional</Trans>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ]
                        : null}
                    </Col>
                  </Row>
                </Form>
              ) : null}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup className={'has-label'}>
                    <Label>
                      <Trans>Piece</Trans>
                    </Label>
                    <Select
                      value={piece}
                      options={pieces.map(t => ({
                        label: t,
                        value: t
                      }))}
                      onChange={event =>
                        this.setState({ piece: event ? event.value : null })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={8}>
                  <Button
                    className="float-right"
                    color={'info'}
                    onClick={() =>
                      this.setState({
                        fromPackage: true
                      })
                    }
                  >
                    <i className="now-ui-icons ui-1_simple-add" />{' '}
                    <Trans>Package</Trans>
                  </Button>
                  <Button
                    className="float-right"
                    color={'info'}
                    onClick={() =>
                      this.setState({
                        fromCatalog: true
                      })
                    }
                  >
                    <i className="now-ui-icons ui-1_simple-add" />{' '}
                    <Trans>Catalog</Trans>
                  </Button>
                  <Button
                    className="float-right"
                    color={'info'}
                    onClick={() => this.setState({ fromCustom: true })}
                  >
                    <i className="now-ui-icons ui-1_simple-add" />{' '}
                    <Trans>Operation</Trans>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {devis ? (
            <Row>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(
                      devisProPriceHT(
                        devis,
                        provider ? provider.proPercent : null
                      )
                    )}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT + Mat</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(
                      devisProPriceHTMaterials(
                        devis,
                        provider ? provider.proPercent : null
                      )
                    )}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>TTC</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(devisPriceTTC(devis))}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>TTC + Mat</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(devisPriceTTCMaterials(devis))}
                  </CardTitle>
                </Card>
              </Col>
            </Row>
          ) : null}

          {devis && devis.groups ? (
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {devis.groups.map((group, key) => {
                return !piece || (piece && piece === group.name) ? (
                  <SortableItem
                    key={`group-${key}`}
                    group={group}
                    index={key}
                    totalGroups={devis.groups.length}
                    provider={provider}
                    addFromCatalogFilter={filters =>
                      this.addFromCatalogFilter(filters)
                    }
                    editOperation={(groupIndex, operationIndex, operation) =>
                      this.editOperation(groupIndex, operationIndex, operation)
                    }
                    deleteOperation={(groupIndex, operationIndex) =>
                      this.deleteOperation(groupIndex, operationIndex)
                    }
                    changeOpOrder={(groupIndex, iIndex, opIndex) =>
                      this.changeOpOrder(groupIndex, iIndex, opIndex)
                    }
                    changeGroupOrder={(groupIndex, iIndex) =>
                      this.changeGroupOrder(groupIndex, iIndex)
                    }
                  />
                ) : null;
              })}
            </SortableContainer>
          ) : null}

          <Row>
            <Col xs={12} md={4} className="equal-height">
              <PaymentsCard
                payments={project ? project.payments : []}
                devis={devis}
              />
            </Col>
            <Col xs={12} md={8} className="equal-height">
              <Card>
                <CardBody>
                  {devis ? (
                    <div>
                      <Label>
                        <Trans>Comments</Trans>
                      </Label>
                      <ReactQuill
                        value={devis.subtitle || ''}
                        modules={quillConfig}
                        className={'quill-text-editor'}
                        onChange={value => {
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              devis: { ...prevState.devis, subtitle: value }
                            };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {fromCustom ? (
            <OpCustomModal
              show={fromCustom}
              provider={provider}
              typeProject={project ? project.type : null}
              onCancel={() => this.setState({ fromCustom: false })}
              onConfirm={operation =>
                this.setState({ fromCustom: false }, () =>
                  this.addOpCustom(operation)
                )
              }
            />
          ) : null}

          {fromCatalog ? (
            <CatalogModal
              show={fromCatalog}
              filters={filters}
              provider={provider}
              typeProject={project ? project.type : null}
              selected={this.getAllOperations()}
              onCancel={() =>
                this.setState({ fromCatalog: false, filters: { piece: null } })
              }
              onConfirm={selected =>
                this.setState(
                  { fromCatalog: false, filters: { piece: null } },
                  () => this.addFromCatalog(selected)
                )
              }
            />
          ) : null}

          {fromPackage ? (
            <PackCatalogModal
              show={fromPackage}
              provider={provider}
              typeProject={project ? project.type : null}
              onCancel={() => this.setState({ fromPackage: false })}
              onConfirm={selected =>
                this.setState({ fromPackage: false }, () =>
                  this.addFromPackages(selected)
                )
              }
            />
          ) : null}

          {changeStatus ? (
            <DevisStatusModal
              show={changeStatus}
              status={devis ? devis.status : null}
              onCancel={() => this.setState({ changeStatus: false })}
              onConfirm={value =>
                this.setState({ changeStatus: false }, () =>
                  this.changeDevisStatus(value)
                )
              }
            />
          ) : null}

          {alert ? (
            <SweetAlert
              danger
              style={{ display: 'block', marginTop: '-100px' }}
              title={t('Warning')}
              onConfirm={() => this.setState(ps => ({ ...ps, alert: null }))}
              confirmBtnBsStyle="info"
              confirmBtnText={t('Accept')}
            >
              {t(alert)}
            </SweetAlert>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DevisEdit));
