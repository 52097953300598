import { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Spinner } from '../../../../components';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  ListGroup
} from 'reactstrap';
import React from 'react';

class Suggestions extends Component {
  render() {
    let { results, loading, query } = this.props;

    if (loading) return <Spinner />;

    if (!results && !results.length && query.length && !loading) {
      return (
        <h6 className="text-center">
          <Trans>No results found for</Trans> {query}
        </h6>
      );
    }

    let idx = 0;

    let groups = {};
    results.forEach(d => {
      let header = d.mode ? d.model.label : d.category;

      if (!groups.hasOwnProperty(header)) {
        groups[header] = [
          {
            label: d.label || d.administrativeLabel || d.model.label,
            id: d.id,
            raw: d
          }
        ];
      } else {
        groups[header].push({
          label: d.label || d.administrativeLabel || d.model.label,
          id: d.id,
          raw: d
        });
      }
    });

    const items = Object.keys(groups)
      .sort()
      .map(region => {
        return [
          <ListGroupItem
            className={'bg-secondary text-white p-1 pl-3'}
            key={`${region.replace(' ', '_')}-header`}
          >
            {region}
          </ListGroupItem>,
          groups[region].map(x => {
            let item = (
              <ListGroupItem
                key={idx}
                option={x}
                position={idx}
                className={'p-1 pl-3'}
                onClick={() => this.props.onSelected(x.raw)}
              >
                <ListGroupItemHeading tag={'strong'}>
                  {`${x.id} - ${x.label}`}
                </ListGroupItemHeading>
                <ListGroupItemText tag={'div'}>
                  {`${x.raw.price} ${x.raw.currency}`}
                </ListGroupItemText>
              </ListGroupItem>
            );

            idx++;
            return item;
          })
        ];
      });

    return <ListGroup flush>{items}</ListGroup>;
  }
}

export default connect()(translate('translations-fr')(Suggestions));
