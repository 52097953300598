import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans, translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AsyncSearch from './AsyncSearch';

class MaterialCatalogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          <Trans>Select Material From Catalog</Trans>
        </ModalHeader>
        <ModalBody>
          <AsyncSearch
            dispatch={this.props.dispatch}
            onSelected={material => this.props.onConfirm(material)}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(MaterialCatalogModal));
