import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../../../components';
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { loadOneDevis, one } from '../../../../helpers/actions/projects';
import { moneyFormatter, quillConfig } from '../../../../helpers/formatters';
import GroupCard from './GroupCard';
import NavbarProject from '../../NavbarActions/NavbarProject';
import PaymentsCard from '../PaymentsCard';

class DevisDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      devis: null,
      project: null,
      provider: null,
      pieces: [],
      piece: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { id, devisId } = this.props.match.params,
      { dispatch } = this.props;

    let promises = [
      dispatch(
        one(id, {
          include: ['devis', 'devisBase', 'children'],
          fields: [
            'type',
            'name',
            'status',
            'devis',
            'devisBase',
            'children',
            'payments'
          ]
        })
      )
    ];

    if (devisId) {
      promises.push(
        dispatch(
          loadOneDevis(id, devisId, {
            fields: [
              'title',
              'price',
              'base',
              'project',
              'groups',
              'parent',
              'status',
              'user',
              'created_at',
              'updated_at'
            ]
          })
        )
      );
    }

    Promise.all(promises)
      .then(async ([project, devis]) => {
        if (project.type === 'compound' && project.children) {
          devis = await this.createDevisFromDevis(project);
        }

        let pieces = [];
        if (devis && devis.groups && devis.groups.length) {
          pieces = devis.groups.map(group => {
            return group.name;
          });
        }

        this.setState(prevState => {
          return {
            ...prevState,
            pieces,
            project,
            devis,
            provider: project.provider,
            loading: false
          };
        });
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  createDevisFromDevis = project => {
    let promises = [];
    let { dispatch } = this.props;

    project.children.forEach(p => {
      if (p._id && p.devis.length) {
        p.devis.map(d =>
          promises.push(
            dispatch(
              loadOneDevis(p._id, d, {
                fields: [
                  'title',
                  'price',
                  'base',
                  'project',
                  'groups',
                  'parent',
                  'status',
                  'user',
                  'created_at',
                  'updated_at'
                ]
              })
            )
          )
        );
      }
    });

    if (promises.length) {
      return Promise.all(promises)
        .then(res => {
          let groups = [];
          let comments = '';

          // create list with all groups (pieces) whitout repeat it
          res.forEach(d => {
            if (d.subtitle)
              comments +=
                '<strong>' +
                d.title +
                '</strong></br></br>' +
                d.subtitle +
                '</br></br>';

            d.groups.forEach(g => {
              if (!groups.find(v => v.name === g.name)) {
                groups.push({
                  name: g.name,
                  items: [],
                  order: 0
                });
              }
            });
          });

          // insert in every group (piece) its items (operations)
          res.forEach(d => {
            d.groups.forEach(g => {
              groups.forEach((v, i) => {
                if (v.name === g.name) {
                  groups[i].items = groups[i].items.concat(g.items);
                }
              });
            });
          });

          return {
            title: 'Devis Compound',
            groups,
            items: [],
            subtitle: comments,
            base: false
          };
        })
        .catch(() => {});
    } else {
      return {
        title: 'Devis Compound',
        groups: [],
        items: [],
        subtitle: '',
        base: false
      };
    }
  };

  componentWillMount() {
    this.load();
  }

  printDevis = (pid, id, type) => {
    return this.props.history.push(
      `/projects/${pid}/devis/${id}/print?type=${type}`
    );
  };

  render() {
    let { loading, project, devis, pieces, piece } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <NavbarProject project={project} history={this.props.history} />

          <Card>
            {devis ? (
              <CardBody>
                <CardTitle tag={'h6'}>{devis.title}</CardTitle>
                <Row>
                  <Col xs={12}>
                    <Switch
                      onText={<i className="now-ui-icons ui-1_check" />}
                      offText={
                        <i className="now-ui-icons ui-1_simple-remove" />
                      }
                      value={devis.base}
                      disabled={true}
                      readonly={true}
                      onColor={'success'}
                      offColor={'danger'}
                    />{' '}
                    <Trans>Devis Base</Trans>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup className={'has-label'}>
                      <Label>
                        <Trans>Piece</Trans>
                      </Label>
                      <Select
                        value={piece}
                        options={pieces.map(t => ({
                          label: t,
                          value: t
                        }))}
                        onChange={event =>
                          this.setState({ piece: event ? event.value : null })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={8}>
                    {project.type === 'compound' && devis ? (
                      <Link
                        key={'button-print'}
                        to={`/projects/${project._id}/compound/devis/print`}
                        className="btn btn-info float-right"
                      >
                        <i className="fa fa-print" />
                      </Link>
                    ) : null}
                    {devis && devis._id ? (
                      <UncontrolledDropdown
                        key={`print-devis-list`}
                        className={'float-right'}
                      >
                        <DropdownToggle color="info" className="" caret>
                          <Trans>Print</Trans>
                        </DropdownToggle>
                        <DropdownMenu right={true}>
                          <DropdownItem
                            onClick={() =>
                              this.printDevis(
                                project._id,
                                devis._id,
                                'provider'
                              )
                            }
                          >
                            <Trans>Provider</Trans>
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              this.printDevis(project._id, devis._id, 'pro')
                            }
                          >
                            <Trans>Professional</Trans>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            ) : null}
          </Card>

          {devis ? (
            <Row>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(get(devis, 'price.proHt', 0))}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>Pro HT + Mat</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(
                      get(devis, 'price.proHt', 0) +
                        get(devis, 'price.materials', 0)
                    )}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>TTC</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(get(devis, 'price.ttc', 0))}
                  </CardTitle>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card className="card-plain text-center mb-0">
                  <h6>
                    <Trans>TTC + Mat</Trans>
                  </h6>
                  <CardTitle tag="h3">
                    {moneyFormatter(
                      get(devis, 'price.ttc', 0) +
                        get(devis, 'price.materials', 0)
                    )}
                  </CardTitle>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!devis
            ? null
            : devis.groups.map((group, key) => {
                return !piece || (piece && piece === group.name) ? (
                  <GroupCard
                    key={key}
                    devis={devis}
                    group={group}
                    groupIndex={key}
                    totalGroups={devis.groups.length}
                  />
                ) : null;
              })}

          <Row>
            <Col xs={12} md={4} className="equal-height">
              <PaymentsCard
                payments={project ? project.payments : []}
                devis={devis}
              />
            </Col>
            <Col xs={12} md={8} className="equal-height">
              <Card>
                <CardBody>
                  {devis ? (
                    <div>
                      <Label>
                        <Trans>Comments</Trans>
                      </Label>
                      <ReactQuill
                        value={devis.subtitle || ''}
                        modules={quillConfig}
                        className={'quill-text-editor'}
                        onChange={value => {
                          this.setState(prevState => {
                            return {
                              ...prevState,
                              devis: { ...prevState.devis, subtitle: value }
                            };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DevisDetails));
